import React from 'react';
import PropTypes, { object } from 'prop-types';
import classnames from 'classnames';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { getChangeLogContent, getIWantContent } from 'components/util';
import './desc-para.desktop.less';
import { POST_TYPE } from '../constants/app';
import formatTime from 'components/util/formatTime';

export const DescPara = ({ desc, isIWant = false, className, type = 0, renderLink = true }) => {
  if (isIWant) {
    const { title, content, solution } = getIWantContent(desc);
    return (
      <div className={classnames('desc_para', className)} >
        <div className="desc_para__want-title">我想要</div>
        <div className="desc_para__want-title-main">
          <div dangerouslySetInnerHTML={dangerouslySetInnerHTML(title, '', 'br', renderLink)} />
        </div>
        <div className="desc_para__want-content">
          <span className="desc_para__want-emoji" aria-hidden>😤 </span>目前痛点
        </div>
        <div className="desc_para__want-content-main">
          <div className="desc_para" dangerouslySetInnerHTML={dangerouslySetInnerHTML(content, '', 'br', renderLink)} />
        </div>
        <div className="desc_para__want-solution">
          <span className="desc_para__want-emoji" aria-hidden>💡 </span>我的想法
        </div>
        <div className="desc_para__want-solution-main">
          <div className="desc_para" dangerouslySetInnerHTML={dangerouslySetInnerHTML(solution, '', 'br', renderLink)} />
        </div>
      </div>
    );
  }
  if (type === POST_TYPE.CHANGE_LOG) {
    const { content, version, versionDate } = getChangeLogContent(desc);
    return (
      <div className={classnames('desc_para', className)}>
        <div className="desc_para__change-log-title">更新日志 {formatTime(versionDate, 'yyyy.MM.dd')}{version ? `（v${version}）` : ''}</div>
        {/* 仅显示第一条内容 */}
        {/* <div style={{ display: !content[0] ? 'none' : 'block' }}>{content[0] && content[0].title}</div> */}
        {/* <div style={{ display: !content[0] ? 'none' : 'block' }} */}
        {/*   className="desc_para__change-log-content" */}
        {/*   dangerouslySetInnerHTML={dangerouslySetInnerHTML(content[0] && content[0].detail)} */}
        {/* /> */}
      </div>
    );
  }
  return (
    <div className={classnames('desc_para', className)} dangerouslySetInnerHTML={dangerouslySetInnerHTML(desc, '', 'br', renderLink)} />
  );
};
DescPara.propTypes = {
  desc: PropTypes.string,
  className: PropTypes.string,
  isIWant: PropTypes.bool,
  type: PropTypes.oneOf([0, ...Object.values(POST_TYPE)]),
  renderLink: PropTypes.bool,
};
