/**
 * 话题选择
 */
import React, {  useEffect, useState, Component } from 'react';
import { Topic } from 'components/topic';
import batchTopic from 'components/api/batch-topic';
import PropTypes from 'prop-types';
import { hrefTopicDetail } from 'components/href-helper';

const TopicContainer = ({ productId, postId, isProduct }) => {
  const [topics, changeTopics] = useState([]);
  useEffect(() => {
    batchTopic.getTopic({ productId, postId, isProduct })
      .then((topics) => {
        if (topics && Array.isArray(topics)) {
          changeTopics(topics);
        }
      });
  }, [productId, postId, isProduct]);
  const onTopicClick = (topic) => {
    hrefTopicDetail(productId, topic.id);
  };
  return topics.map(topic => (
    <Topic topic={topic} key={topic?.id} onClick={onTopicClick} />
  ));
};

TopicContainer.propTypes = {
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  postId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isProduct: PropTypes.bool,
};

export default TopicContainer;
