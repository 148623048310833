/**
 * 话题选择
 */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { handleKeyDown } from 'components/util/aria/handleKeyDown';
import { isPc as isPC } from 'components/env';
import Tag from 'components/tag';
import './style.less';
import { IconTopicBlue, IconTopicGray } from '../IconSystem';

export const Topic = ({ topic, disabled, showCancel, onClick, onCancel, isDefault }) => {
  const cls = 'topic';

  /** 处理删除按钮点击 */
  const handleCancel = (e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    if (onCancel && typeof onCancel === 'function') {
      onCancel(topic);
    }
  };

  /** 处理话题整个被点击 */
  const handleTopicClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    if (onClick && typeof onClick === 'function') {
      onClick(topic);
    }
  };

  // 防止topic未加载
  if (!topic || !topic.id) {
    return null;
  }

  return (
    <Tag.div
      className={classnames(`${cls}`, {
        [`${cls}--disabled`]: disabled,
        [`${cls}--default`]: isDefault,
        [`${cls}--show-cancel`]: showCancel,
        [`${cls}--pc`]: isPC,
        [`${cls}--mobile`]: !isPC,
      })}
      onClick={handleTopicClick}
      onKeyDown={handleKeyDown(handleTopicClick)}
      role="button"
      tabIndex={0}
    >
      {isDefault ? <IconTopicGray /> : <IconTopicBlue />}
      <Tag.span className={`${cls}__title`}>{topic && topic.title}</Tag.span>
      {showCancel && <Tag.div
        className={`${cls}__close`}
        onClick={handleCancel}
        onKeyDown={handleKeyDown(handleCancel)}
        role="button"
        tabIndex={0}
      />}
    </Tag.div>
  );
};

Topic.propTypes = {
  topic: PropTypes.object.isRequired,
  /** 是否禁止用户修改 */
  disabled: PropTypes.bool,
  /** 是否默认样式 */
  isDefault: PropTypes.bool,
  /** 是否显示删除icon */
  showCancel: PropTypes.bool,
  /** 话题点击事件 */
  onClick: PropTypes.func,
  /** 话题删除按钮点击事件 */
  onCancel: PropTypes.func,
};
