import BatchAction from 'components/util/batch-action';
import { getEnterprisePostTopics, postProductPostTopics } from 'components/api';
import {  getProductId, getContentId } from 'components/util';
class BatchTopic {
  topics = {}
  actionList = []

  constructor() {
    /** batchaction 实例 */
    // 企业话题
    this.batchAction = new BatchAction({
      asyncAction: getEnterprisePostTopics,
      callback: this.callback,
      paramsFormat: this.paramsForamt,
    });
    // 产品话题
    this.batchProductAction = new BatchAction({
      asyncAction: postProductPostTopics,
      callback: this.callbackProduct,
      paramsFormat: this.paramsForamtProduct,
    });
  }

  paramsForamt = params => ({
    enterpriseId: getProductId(),
    ids: params.map(item => ({
      product_id: item.productId,
      content_ids: item.postId,
    })),
  })

  paramsForamtProduct = params => ({
    productId: getProductId() || params[0].productId,
    content_ids: params.map(item => item.postId),
  })

  // 处理下话题的返回值，
  callback = (res) => {
    if (res?.data?.list && res?.data?.setting) {
      const { list, setting } = res.data;
      // 这里后端返回的结构为了减少数据冗余，格式有点麻烦要转一下
      Object.keys(list).forEach((productId) => {
        if (!this.topics[productId]) {
          this.topics[productId] = {};
        }
        Object.keys(list[productId]).forEach((postId) => {
          // 这里话题是话题ID的数组。没有title 要去setting查
          const topic = list[productId][postId].topics.map((id) => {
            const { title } = setting?.[productId]?.[id];
            return {
              title,
              id,
            };
          });
          this.topics[productId][postId] = topic;
        });
      });

      // 去到对应的帖子话题返回给对应请求
      this.actionList.forEach((action) => {
        const {
          productId,
          postId,
          resolve,
        } = action;
        const topic = this.topics?.[productId]?.[postId];
        resolve(topic);
      });
      this.actionList = [];
    }
  }

  callbackProduct = (res) => {
    if (res?.data?.list && res?.data?.setting) {
      const { list, setting } = res.data;
      Object.keys(list).forEach((postId) => {
        const topic = list[postId].topics.map((id) => {
          const { title } = setting?.[id];
          return {
            title,
            id,
          };
        });
        this.topics[postId] = topic;
      });

      // 去到对应的帖子话题返回给对应请求
      this.actionList.forEach((action) => {
        const {
          postId,
          resolve,
        } = action;
        const topic = this.topics?.[postId];
        resolve(topic);
      });
      this.actionList = [];
    }
  }

  addAction = (action) => {
    this.actionList.push(action);
  }

  // 获取话题信息
  getTopic = ({ productId, postId, isProduct }) => {
    const topics = this.topics[productId]?.[postId];
    // 每次请求完把帖子话题给缓存下来。如果遇到重复请求同一个帖子的话题直接读取缓存，
    if (topics) {
      return new Promise((resolve) => {
        resolve(topics);
      });
    }

    // 缓存里没有当前话题就发起请求从服务端拿
    return new Promise((resolve) => {
      this.addAction({
        productId,
        postId,
        resolve,
      });
      isProduct
        ? this.batchProductAction.targetAction({ productId, postId })
        : this.batchAction.targetAction({ productId, postId });
    });
  }
}

export default new BatchTopic();
