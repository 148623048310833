export const TAG_COLOR = [
  { color: '#78C288', font: '#228137', bg: '#E3FEE9', bd: '#4AA15D' },
  { color: '#69ABE4', font: '#156EBC', bg: '#DEF0FF', bd: '#509ADA' },
  { color: '#9E8BE1', font: '#5F44BC', bg: '#E9E3FF', bd: '#8C77D5' },
  { color: '#DDA44C', font: '#8B682D', bg: '#FFF1DB', bd: '#C0842A' },
  { color: '#71C1C2', font: '#077B7D', bg: '#D1FAFA', bd: '#43A1A3' },
  { color: '#E88E61', font: '#B84D0A', bg: '#FFEEE6', bd: '#E0743E' },
  { color: '#E4698A', font: '#C32851', bg: '#FFE0E9', bd: '#E4698A' },
  { color: '#C381C8', font: '#9B4AA1', bg: '#FDE3FF', bd: '#B264CC' },
  { color: '#6A7BD5', font: '#2F41A0', bg: '#E2E6FF', bd: '#6A7BD5' },
  { color: '#9D8A85', font: '#6C534C', bg: '#FAEDE9', bd: '#9D8A85' },
  { color: '#849CAA', font: '#526B79', bg: '#E0E9EE', bd: '#7393A6' },
  { color: '#D0A17F', font: '#7E5A3F', bg: '#F0E5DD', bd: '#C08154' },
  { color: '#9AA77F', font: '#57623E', bg: '#E4EAD9', bd: '#859664' },
  { color: '#B15555', font: '#B15555', bg: '#F1DFDF', bd: '#B35B5B' },
  { color: '#AE9F6D', font: '#6C6036', bg: '#E5E0D0', bd: '#9D8D53' },
];
