import React from 'react';
import { isEmpty } from 'lodash-es';
import { TAG_COLOR } from './constants';
import './style.less';
import PropTypes from 'prop-types';

export const TagShow = (props) => {
  function styleyClass(item) {
    const selectColor = TAG_COLOR.find(i => i.color === item.setting.color) || {};
    return {
      backgroundColor: selectColor.bd || '#eee',
      color: '#fff',
    };
  }
  return (
    <>
      {
        !isEmpty(props.tags)
        && props.tags.map((item, i) => (
          item && <span className="badge" key={`${i}`} style={styleyClass(item)}>{item.name}</span>
        ))
      }
    </>
  );
};

TagShow.propTypes = {
  tags: PropTypes.array,
};
