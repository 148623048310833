/**
 * 处理点击事件的键盘操作，通常用于回车，
 * 通过key判断，而不是通过keyCode判断
 * NOTE: [告别JS keyCode](https://www.zhangxinxu.com/wordpress/2021/01/js-keycode-deprecated/)
 */
export const handleKeyDown =  (handler, key = 'Enter') => (event) => {
  if (!handler || typeof handler !== 'function') return;
  if (event.key === key) {
    handler(event);
  }
};
